export default [
    {
        path: "sales/:sales_invoice_id/:type?",
        name: "print-sales.list",
        component: () =>
            import ("@/view/pages/modules/sales/invoices/Print.vue"),
        meta: {role: ['sales_invoice_print.create', 'sales_invoices.printing_export_pdf', 'pos_session.printing_export_pdf']}
    },
    {
        path: "packing/:sales_invoice_id/:type?",
        name: "packing-sales.list",
        component: () =>
            import ("@/view/pages/modules/sales/invoices/packingSlip.vue"),
        meta: {role: ['sales_invoices.pdf_print_packing_slip']}
    },
    {
        path: "packing-general-sales/:sales_invoice_id/:type?",
        name: "packing-general-sales.list",
        component: () =>
            import ("@/view/pages/modules/sales/generalSales/packingSlip.vue"),
        meta: {role: ['general_sales.pdf_print_packing_slip']}
    },
    {
        path: "sales-credit/:sales_invoice_id/:type?",
        name: "print-sales-credits.list",
        component: () =>
            import ("@/view/pages/modules/sales/credits/Print.vue"),
        meta: {role: ['credits.printing_export_pdf']}
    },
    {
        path: "general-sales-credit/:sales_invoice_id/:type?",
        name: "print-general-sales-credits.list",
        component: () =>
            import ("@/view/pages/modules/sales/generalCredits/Print.vue"),
        meta: {role: ['general_credits.printing_export_pdf']}
    },
    {
        path: "sales-debit/:sales_invoice_id/:type?",
        name: "print-sales-debits.list",
        component: () =>
            import ("@/view/pages/modules/sales/debits/Print.vue"),
        meta: {role: ['sales_debit.printing_export_pdf']}
    },
    {
        path: "general-sales-debit/:sales_invoice_id/:type?",
        name: "print-general-sales-debits.list",
        component: () =>
            import ("@/view/pages/modules/sales/generalDebits/Print.vue"),
        meta: {role: ['general_debit.printing_export_pdf']}
    },
    {
        path: "sales-estimate/:sales_invoice_id/:type?",
        name: "print-sales-estimate.list",
        component: () =>
            import ("@/view/pages/modules/sales/estimations/Print.vue"),
        meta: {role: ['estimations.printing_export_pdf']}
    },
    {
        path: "sales-refund-80/:sales_invoice_id/:type?",
        name: "print-sales-refund.list",
        component: () =>
            import ("@/view/pages/modules/sales/refund/Print80MM.vue"),
        meta: {role: ['sales_refund.printing_export_pdf']}
    },
    {
        path: "general-sales-refund-80/:general_sales_invoice_id/:type?",
        name: "print-general-sales-refund.list",
        component: () =>
            import ("@/view/pages/modules/sales/generalRefund/Print80MM.vue"),
        meta: {role: ['general_sales_refund.printing_export_pdf']}
    },
    {
        path: "general-sales/:sales_invoice_id/:type?",
        name: "print-general-sales.list",
        component: () =>
            import ("@/view/pages/modules/sales/generalSales/Print.vue"),
        meta: {role: ['general_sales.printing_export_pdf']}
    },
    {
        path: "purchase-order/:purchase_invoice_id/:type?",
        name: "print-purchase-order.list",
        component: () =>
            import ("@/view/pages/modules/purchases/purchaseOrder/Print.vue"),
        meta: {role: ['purchase_orders.printing_export_pdf']}
    },

    {
        path: "purchase-invoice/:purchase_invoice_id/:type?",
        name: "print-purchase-invoice.list",
        component: () =>
            import ("@/view/pages/modules/purchases/purchaseInvoice/Print.vue"),
        meta: {role: ['purchases_invoices.printing_export_pdf']}
    },
    {
        path: "purchaseRefund/:purchase_invoice_id/:type?",
        name: "print-purchase-refund.list",
        component: () =>
            import ("@/view/pages/modules/purchases/purchaseRefund/Print2.vue"),
        meta: {role: ['purchases_refunds.printing_export_pdf']}
    },

    {
        path: "purchaseInvoice/:id/:type?",
        name: "print-purchase-invoice.list",
        component: () =>
            import ("@/view/pages/modules/purchases/purchaseInvoice/PrintTaxInvoice.vue"),
        meta: {role: ['purchases_invoices.printing_export_pdf']}
    },
    {
        path: "purchase-refund/:id/:type?",
        name: "print-purchase-refund.list",
        component: () =>
            import ("@/view/pages/modules/purchases/purchaseRefund/PrintTaxInvoice.vue"),
        meta: {role: ['purchases_refunds.printing_export_pdf']}
    },


    {
        path: "point-sale-invoice",
        name: "point-sale-invoice.list",
        component: () =>
            import ("@/view/pages/modules/pos/pointOfSales/Print.vue"),
        // meta: { role: ['packing_slip.create'] }
    },
    {
        path: "point-request-sale-invoice",
        name: "point-request-sale-invoice.list",
        component: () =>
            import ("@/view/pages/modules/pos/pointOfSales/PrintRequest.vue"),
        // meta: { role: ['packing_slip.create'] }
    },
    {
        path: "point-laundry-sale-invoice",
        name: "point-laundry-sale-invoice.list",
        component: () =>
            import ("@/view/pages/modules/pos/pointOfSalesLaundry/Print.vue"),
        // meta: { role: ['packing_slip.create'] }
    },
    {
        path: "point-sale-invoice-refund",
        name: "point-sale-invoice-refund.list",
        component: () =>
            import ("@/view/pages/modules/pos/pointOfSales/PrintRefund.vue"),
        // meta: { role: ['packing_slip.create'] }
    },
    {
        path: "point-sale-credit/:id",
        name: "point-sale-credit.list",
        component: () =>
            import ("@/view/pages/modules/pos/pointOfSales/PrintCredit.vue"),
        // meta: { role: ['point-sale-credit.create'] }
    },
    {
        path: "pos-sessions/:id/:action",
        name: "pos-sessions.print",
        component: () =>
            import ("@/view/pages/modules/pos/posSessions/Print.vue"),
        // meta: { role: ['pos_session.create'] }
    },
    {
        path: "pos-sessions/pos-refund/:id/:action",
        name: "pos-refund.print",
        component: () =>
            import ("@/view/pages/modules/pos/posSessions/refund/Print.vue"),
        meta: {role: ['pos_refund.printing_export_pdf']}
    },
    {
        path: "sales/payment/:id/:action",
        name: "payment.print",
        component: () =>
            import ("@/view/pages/modules/sales/paymentSalesInvoices/Print.vue"),
    },
    {
        path: "purchases/payment/:id/:action",
        name: "payment_purchases.print",
        component: () =>
            import ("@/view/pages/modules/purchases/paymentPurchaseInvoices/Print.vue"),
    },
    {
        path: "summary-report",
        name: "summary-report.export",
        component: () =>
            import ("@/view/pages/modules/statistics/dailyBriefReportInvoices/components/SummaryPdf.vue"),
    },
    {
        path: "inventory-transactions/:id/:type?",
        name: "print-inventory-transactions.list",
        component: () =>
            import ("@/view/pages/modules/items/inventoryTransactions/Print.vue"),
        meta: {role: ['inventory-transactions.printing_export_pdf', 'inventory-transactions.printing_export_pdf_without_price']}
    },
    {
        path: "print-expenses-transactions/:id/:type?",
        name: "print-expenses-transactions.list",
        component: () =>
            import ("@/view/pages/modules/finances/expensesIncomeTransaction/expensesPrint.vue"),
        meta: {role: ['expenses_incomes.printing_export_pdf']}
    },
    {
        path: "print-incomes-transactions/:id/:type?",
        name: "print-incomes-transactions.list",
        component: () =>
            import ("@/view/pages/modules/finances/expensesIncomeTransaction/incomePrint.vue"),
        meta: {role: ['expenses_incomes.printing_export_pdf']}
    },
    {
        path: "supplier-account-statement/:id",
        name: "supplier-account-statement.list",
        component: () =>
            import ("@/view/pages/modules/purchases/suppliers/accountStatement.vue"),
        meta: {role: ['suppliers.account_statement']}
    },
    {
        path: "customer-account-statement/:id/:type?",
        name: "customer-account-statement.list",
        component: () =>
            import ("@/view/pages/modules/customers/customers/accountStatement.vue"),
        meta: {role: ['customers.account_statement']}
    },

    {
        path: "sales/package-label-print/:id/:type?",
        name: "package-label-print.list",
        component: () =>
            import ("@/view/pages/modules/sales/invoices/packageLabelPrint.vue"),
        meta: {role: ['sales_invoices.pdf_package']}
    },

    {
        path: "sales/package-label-print-general-sales/:id/:type?",
        name: "package-label-print-general-sales.list",
        component: () =>
            import ("@/view/pages/modules/sales/generalSales/packageLabelPrint.vue"),
        meta: {role: ['general_sales.pdf_package']}
    },


    {
        path: "sales/receive-list-print/:id/:type?",
        name: "receive-list-print.list",
        component: () =>
            import ("@/view/pages/modules/sales/invoices/receiveListPrint.vue"),
        meta: {role: ['sales_invoices.print_pdf_receive']}
    },
    {
        path: "sales/receive-list-print-general-sales/:id/:type?",
        name: "receive-list-print-general-sales.list",
        component: () =>
            import ("@/view/pages/modules/sales/generalSales/receiveListPrint.vue"),
        meta: {role: ['general_sales.print_pdf_receive']}
    },

    {
        path: "sales/delivery-label-print/:id/:type?",
        name: "delivery-label-print.list",
        component: () =>
            import ("@/view/pages/modules/sales/invoices/deliveryLabelPrint.vue"),
        meta: {role: ['sales_invoices.print_pdf_delivery']}
    },

    {
        path: "sales/delivery-label-print-general-sales/:id/:type?",
        name: "delivery-label-print-general-sales.list",
        component: () =>
            import ("@/view/pages/modules/sales/generalSales/deliveryLabelPrint.vue"),
        meta: {role: ['general_sales.print_pdf_delivery']}
    },
    {
        path: "cash-movement/:id/:type?",
        name: "print-cash-movement.list",
        component: () =>
            import ("@/view/pages/modules/pos/cashMovement/Print.vue"),
        meta: {role: ['cash_movement.printing_export_pdf']}
    },

    {
        path: "print-treasury-checking-history/:type",
        name: "print-treasury-checking-history.list",
        component: () =>
            import ("@/view/pages/modules/finances/treasuryCheckingHistory/Print.vue"),
        // meta: { role: ['expenses_incomes.create'] }
    },

    {
        path: "print-bank-checking-history/:type",
        name: "print-bank-checking-history.list",
        component: () =>
            import ("@/view/pages/modules/finances/bankCheckingHistory/Print.vue"),
        // meta: { role: ['expenses_incomes.create'] }
    },

    {
        path: "print-sales-quotations/:id/:type?",
        name: "print-sales-quotations.list",
        component: () =>
            import ("@/view/pages/modules/sales/salesQuotations/Print.vue"),
        meta: {role: ['sales_quotations.printing_export_pdf']}
    },
    {
        path: "sales-tax-invoice/:id/:type?/:status?",
        name: "print-sales-tax-invoice.list",
        component: () =>
            import ("@/view/pages/modules/sales/invoices/PrintTaxInvoice.vue"),
            // import ("@/view/pages/modules/sales/invoices/PrintTaxInvoice/PrintTaxInvoice.vue"),
        meta: {role: ['sales_invoices.pdf_print_tax_invoice', 'sales_invoices.pdf_print_tax_invoice_simplified']}
    },

    {
        path: "sales-80mm-invoice/:sales_invoice_id/:type?",
        name: "print-sales-80mm-invoice.list",
        component: () =>
            import ("@/view/pages/modules/sales/invoices/Print80MM.vue"),
        meta: {role: ['sales_invoices.printing_export_pdf']}
    },

    {
        path: "general-sales-tax-invoice/:id/:type?/:status?",
        name: "print-general-sales-tax-invoice.list",
        component: () =>
            import ("@/view/pages/modules/sales/generalSales/PrintTaxInvoice.vue"),
        meta: {role: ['sales_invoices.pdf_print_tax_invoice', 'general_sales.pdf_print_tax_invoice_simplified']}
    },

    {
        path: "print-general-sales-quotations/:id/:type?",
        name: "print-general-sales-quotations.list",
        component: () =>
            import ("@/view/pages/modules/sales/generalSalesQuotations/Print.vue"),
        meta: {role: ['general_sales_quotations.printing_export_pdf']}
    },

    {
        path: "general-sales-tax-invoice-refund/:sales_invoice_id/:type?/:status?",
        name: "print-general-sales-tax-invoice-refund.list",
        component: () =>
            import ("@/view/pages/modules/sales/generalRefund/PrintTaxInvoice.vue"),
        meta: {role: ['general_sales_refund.pdf_print_tax_invoice', 'general_sales_refund.pdf_print_tax_invoice_simplified']}
    },

    {
        path: "general-sales-80mm-invoice/:sales_invoice_id/:type?",
        name: "print-general-sales-80mm-invoice.list",
        component: () =>
            import ("@/view/pages/modules/sales/generalSales/Print80MM.vue"),
        meta: {role: ['general_sales.printing_export_pdf']}
    },

    {
        path: "laundry-sales-tax-invoice/:sales_invoice_id/:type?/:status?",
        name: "print-laundry-sales-tax-invoice.list",
        component: () =>
            import ("@/view/pages/modules/sales/laundrySales/PrintTaxInvoice.vue"),
        meta: {role: ['sales_laundry_services.pdf_print_tax_invoice', 'sales_laundry_services.pdf_print_tax_invoice_simplified']}
    },
    {
        path: "credits-tax-invoice/:id/:type?/:status?",
        name: "print-credits-tax-invoice.list",
        component: () =>
            import ("@/view/pages/modules/sales/credits/PrintTaxInvoice.vue"),
        meta: {role: ['credits.pdf_print_tax_invoice', 'credits.pdf_print_tax_invoice_simplified']}
    },
    {
        path: "general-credits-tax-invoice/:id/:type?/:status?",
        name: "print-general-credits-tax-invoice.list",
        component: () =>
            import ("@/view/pages/modules/sales/generalCredits/PrintTaxInvoice.vue"),
        meta: {role: ['general_credits.pdf_print_tax_invoice', 'general_credits.pdf_print_tax_invoice_simplified']}
    },
    {
        path: "debits-tax-invoice/:sales_invoice_id/:type?/:status?",
        name: "print-debits-tax-invoice.list",
        component: () =>
            import ("@/view/pages/modules/sales/debits/PrintTaxInvoice.vue"),
        meta: {role: ['sales_debit.pdf_print_tax_invoice', 'sales_debit.pdf_print_tax_invoice_simplified']}
    },
    {
        path: "general-debits-tax-invoice/:sales_invoice_id/:type?/:status?",
        name: "print-general-debits-tax-invoice.list",
        component: () =>
            import ("@/view/pages/modules/sales/generalDebits/PrintTaxInvoice.vue"),
        meta: {role: ['general_debit.pdf_print_tax_invoice', 'general_debit.pdf_print_tax_invoice_simplified']}
    },
    {
        path: "sales-tax-invoice-refund/:sales_invoice_id/:type?/:status?",
        name: "print-sales-tax-invoice-refund.list",
        component: () =>
            import ("@/view/pages/modules/sales/refund/PrintTaxInvoice.vue"),
        meta: {role: ['sales_refund.pdf_print_tax_invoice', 'sales_refund.pdf_print_tax_invoice_simplified']}
    },

    {
        path: "print-incomes-transactions-customer/:id/:type?",
        name: "print-incomes-transactions-customer.list",
        component: () =>
            import ("@/view/pages/modules/customers/customers/incomePrint.vue"),
        meta: {role: ['customers.update']}
    },
    {
        path: "sales-rent-tax-invoice/:sales_invoice_id/:type?/:status?",
        name: "print-sales-rent-tax-invoice.list",
        component: () =>
            import ("@/view/pages/modules/sales/salesRent/PrintTaxInvoice.vue"),
        meta: {role: ['sales_invoices.pdf_print_tax_invoice', 'sales_rent.pdf_print_tax_invoice_simplified']}
    },

    {
        path: "general-sales-rent-tax-invoice/:sales_invoice_id/:type?/:status?",
        name: "print-general-sales-rent-tax-invoice.list",
        component: () =>
            import ("@/view/pages/modules/sales/generalSalesRent/PrintTaxInvoice.vue"),
        meta: {role: ['sales_invoices.pdf_print_tax_invoice', 'general_sales_rent.pdf_print_tax_invoice_simplified']}
    },
    {
        path: "payment-invoice/:id/:type?",
        name: "print-payment-invoice.list",
        component: () =>
            import ("@/view/pages/modules/sales/paymentSalesInvoices/PrintPayment.vue"),
        meta: {role: ['payment_sales_invoices.printing_export_pdf_financial']}
    },

    {
        path: "pos-laundry-sales-80mm-invoice/:sales_invoice_id/:type?",
        name: "pos-laundry-sales-80mm-invoice.list",
        component: () =>
            import ("@/view/pages/modules/pos/pointOfSalesLaundry/Print.vue"),
        // meta: { role: ['sales_invoices.printing_export_pdf'] }
    },

    {
        path: "print-outside/pos/pos-sales-invoice-80mm/:sales_invoice_id/:type?",
        name: "pos-sales-invoice-80mm.list",
        component: () =>
            import ("@/view/pages/modules/printingOutSide/pos/Print80MM.vue"),
    },
    {
        path: "print-outside/pos/pos-credit-invoice-80mm/:id/:type?",
        name: "pos-credit-80mm.list",
        component: () =>
            import ("@/view/pages/modules/printingOutSide/pos/PrintCredit.vue"),
    },
    {
        path: "print-outside/pos/pos-refund-invoice-80mm/:id/:type?",
        name: "pos-refund-80mm.list",
        component: () =>
            import ("@/view/pages/modules/printingOutSide/pos/PrintRefund.vue"),
    },

    {
        path: "pos/session-details/:id/:action?",
        name: "pos-session-invoice.list",
        component: () =>
            import ("@/view/pages/modules/pos/posSessions/Print.vue"),
    },

    {
        path: "pos/session-details-80mm/:id/:action?",
        name: "pos-session-invoice-80mm.list",
        component: () =>
            import ("@/view/pages/modules/pos/posSessions/Print80MM.vue"),
    },

    /////////////////////////////START print-outside A4 //////////////////////////////////////
    {
        path: "print-outside/sales/sales-invoice-a4-tax/:sales_invoice_id/:type?",
        name: "sales-invoice-a4-tax.list",
        component: () =>
            import ("@/view/pages/modules/printingOutSide/sales/salesInvoice/PrintTaxInvoiceA4/PrintTaxInvoice.vue"),
    },
    {
        path: "print-outside/sales/sales-invoice-a4/:sales_invoice_id/:type?",
        name: "sales-invoice-a4-tax.list",
        component: () =>
            import ("@/view/pages/modules/printingOutSide/sales/salesInvoice/PrintA4WithoutTax.vue"),
    },
    {
        path: "print-outside/sales/general-sales-invoice-a4-tax/:sales_invoice_id/:type?",
        name: "general-sales-invoice-a4-tax.list",
        component: () =>
            import ("@/view/pages/modules/printingOutSide/sales/generalSales/PrintTaxInvoiceA4.vue"),
    },
    {
        path: "print-outside/sales/general-sales-invoice-a4/:sales_invoice_id/:type?",
        name: "general-sales-invoice-a4.list",
        component: () =>
            import ("@/view/pages/modules/printingOutSide/sales/generalSales/PrintA4WithoutTax.vue"),
    },
    {
        path: "print-outside/sales/sales-quotation-a4-tax/:id/:type?",
        name: "sales-quotation-a4-tax.list",
        component: () =>
            import ("@/view/pages/modules/printingOutSide/sales/salesQuotation/PrintA4.vue"),
    },
    {
        path: "print-outside/sales/general-sales-quotation-a4-tax/:id/:type?",
        name: "general-sales-quotation-a4-tax.list",
        component: () =>
            import ("@/view/pages/modules/printingOutSide/sales/generalSalesQuotation/PrintA4.vue"),
    },
    /////////////////////////////END print-outside A4 //////////////////////////////////////

    {
        path: "sales-rent-80mm-invoice/:sales_invoice_id/:type?",
        name: "print-sales-rent-80mm-invoice.list",
        component: () =>
            import ("@/view/pages/modules/sales/salesRent/Print80MM.vue"),
        meta: {role: ['sales_rent.printing_export_pdf']}
    },


    {
        path: "printing/test/:sales_invoice_id/:type?",
        name: "printing-test.list",
        component: () =>
            import ("@/view/pages/modules/printing/sales/test.vue"),
        // meta: {role: ['sales_rent.printing_export_pdf']}
    },
    {
        path: "printing/test2/:sales_invoice_id/:type?",
        name: "printing-test-2.list",
        component: () =>
            import ("@/view/pages/modules/printing/sales/test2.vue"),
        // meta: {role: ['sales_rent.printing_export_pdf']}
    },
];