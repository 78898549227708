export default [
  {
    path: "sales/estimations",
    name: "estimations.index",
    component: () =>
        import ("@/view/pages/modules/sales/estimations/Index.vue"),
    meta: {"role":["estimations.list"]},
  },
  {
    path: "sales/estimations/create",
    name: "estimations.create",
    component: () =>
        import ("@/view/pages/modules/sales/estimations/Form.vue"),
    meta: {"role":["estimations.create"]},
  },
  {
    path: "sales/estimations/edit/:id",
    name: "estimations.edit",
    component: () =>
        import ("@/view/pages/modules/sales/estimations/Form.vue"),
    meta: {"role":["estimations.update"]},
  },
  {
    path: "sales/estimations/clone/:clone_id",
    name: "estimations.clone",
    component: () =>
        import ("@/view/pages/modules/sales/estimations/Form.vue"),
    meta: {"role":["estimations.clone"]},
  },
  {
    path: "sales/attachment-notes/create/:type_id/:type",
    name: "attachment-notes.create",
    component: () =>
        import ("@/view/pages/modules/sales/attachmentNotes/Form.vue"),
    meta: {"role":["attachment_notes.create","sales_invoices.attachment_notes","estimations.attachment_notes","employees.attachment_notes","general_sales.attachment_notes"]},
  },
  {
    path: "sales/sales_invoices",
    name: "sales_invoices.index",
    component: () =>
        import ("@/view/pages/modules/sales/invoices/Index.vue"),
    meta: {"role":["sales_invoices.list"]},
  },
  {
    path: "sales/sales_invoice/payment_details/:id",
    name: "sales_invoices.payment_details",
    component: () =>
        import ("@/view/pages/modules/sales/invoices/PaymentDetails.vue"),
    meta: {"role":["sales_invoices.payment_details"]},
  },
  {
    path: "sales/sales_invoices/create/:quotation_requests_id?/:quotation_type?",
    name: "sales_invoices.create",
    component: () =>
        import ("@/view/pages/modules/sales/invoices/Form.vue"),
    meta: {"role":["sales_invoices.create","sales_quotations.convert_to_sales_invoice"]},
  },
  {
    path: "sales/sales_invoices/edit/:id",
    name: "sales_invoices.edit",
    component: () =>
        import ("@/view/pages/modules/sales/invoices/Form.vue"),
    meta: {"role":["sales_invoices.update"]},
  },
  {
    path: "sales/sales_invoices/view/:id",
    name: "sales_invoices.view",
    component: () =>
        import ("@/view/pages/modules/sales/invoices/View.vue"),
    meta: {"role":["sales_invoices.view"]},
  },
  {
    path: "sales/sales_invoices/clone/:clone_id",
    name: "sales_invoices.clone",
    component: () =>
        import ("@/view/pages/modules/sales/invoices/Form.vue"),
    meta: {"role":["sales_invoices.clone"]},
  },
  {
    path: "sales/cost-center/create/:sales_invoice_id",
    name: "cost-center.create",
    component: () =>
        import ("@/view/pages/modules/sales/costCenter/Form.vue"),
    meta: {"role":["add_to_cost_center.create","sales_invoices.add_to_cost_center"]},
  },
  {
    path: "sales/attachment-notes/create/:type_id/:type",
    name: "attachment-notes.create",
    component: () =>
        import ("@/view/pages/modules/sales/attachmentNotes/Form.vue"),
    meta: {"role":["attachment_notes.create"]},
  },
  {
    path: "sales/credits",
    name: "credits.index",
    component: () =>
        import ("@/view/pages/modules/sales/credits/Index.vue"),
    meta: {"role":["credits.list"]},
  },
  {
    path: "sales/credits/create/:sale_invoice_id?",
    name: "credits.create",
    component: () =>
        import ("@/view/pages/modules/sales/credits/Form.vue"),
    meta: {"role":["credits.create","sales_invoices.add_sales_credit"]},
  },
  {
    path: "sales/credits/edit/:id",
    name: "credits.edit",
    component: () =>
        import ("@/view/pages/modules/sales/credits/Form.vue"),
    meta: {"role":["credits.update"]},
  },
  {
    path: "sales/general-credits",
    name: "general-credits.index",
    component: () =>
        import ("@/view/pages/modules/sales/generalCredits/Index.vue"),
    meta: {"role":["general_credits.list"]},
  },
  {
    path: "sales/general-credits/create/:sale_invoice_id?",
    name: "general-credits.create",
    component: () =>
        import ("@/view/pages/modules/sales/generalCredits/Form.vue"),
    meta: {"role":["general_credits.create","general_sales.add_sales_credit"]},
  },
  {
    path: "sales/general-credits/edit/:id",
    name: "general-credits.edit",
    component: () =>
        import ("@/view/pages/modules/sales/generalCredits/Form.vue"),
    meta: {"role":["general_credits.update"]},
  },
  {
    path: "sales/sales_shipping",
    name: "sales_shipping.index",
    component: () =>
        import ("@/view/pages/modules/sales/shipping/Index.vue"),
  },
  {
    path: "sales/sales_shipping/create",
    name: "sales_shipping.create",
    component: () =>
        import ("@/view/pages/modules/sales/shipping/Form.vue"),
    meta: {"role":["sales_shipping.create"]},
  },
  {
    path: "sales/sales_shipping/edit/:id",
    name: "sales_shipping.edit",
    component: () =>
        import ("@/view/pages/modules/sales/shipping/Form.vue"),
    meta: {"role":["sales_shipping.update"]},
  },
  {
    path: "sales/sales_configuration",
    name: "sales_configuration.index",
    component: () =>
        import ("@/view/pages/modules/sales/configuration/Form.vue"),
    meta: {"role":["sales_configuration.update"]},
  },
  {
    path: "sales/sales_refund",
    name: "sales_refund.index",
    component: () =>
        import ("@/view/pages/modules/sales/refund/Index.vue"),
    meta: {"role":["sales_refund.list"]},
  },
  {
    path: "sales/sales_refund/create/:sales_invoice_id",
    name: "sales_refund.create",
    component: () =>
        import ("@/view/pages/modules/sales/refund/Form.vue"),
    meta: {"role":["sales_refund.create","sales_invoices.sales_refund"]},
  },
  {
    path: "sales/sales_refund/edit/:id",
    name: "sales_refund.edit",
    component: () =>
        import ("@/view/pages/modules/sales/refund/Form.vue"),
    meta: {"role":["sales_refund.update"]},
  },
  {
    path: "sales/payment_sales_invoices",
    name: "payment_sales_invoices.index",
    component: () =>
        import ("@/view/pages/modules/sales/paymentSalesInvoices/Index.vue"),
    meta: {"role":["payment_sales_invoices.list"]},
  },
  {
    path: "sales/payment_sales_invoices/create/:sales_invoice_id/:type?",
    name: "payment_sales_invoices.create",
    component: () =>
        import ("@/view/pages/modules/sales/paymentSalesInvoices/Form.vue"),
    meta: {"role":["payment_sales_invoices.create","sales_invoices.payment_sales_invoices","general_sales.payment_sales_invoices","sales_refund.payment_sales_invoices","sales_debit.payment_sales_invoices","credits.payment_sales_invoices","sales_rent.payment_sales_invoices","general_sales_rent.payment_sales_invoices"]},
  },
  {
    path: "sales/payment_sales_invoices/edit/:id/:type?",
    name: "payment_sales_invoices.edit",
    component: () =>
        import ("@/view/pages/modules/sales/paymentSalesInvoices/Form.vue"),
    meta: {"role":["payment_sales_invoices.update"]},
  },
  {
    path: "sales/payment_sales_invoices/details/:id",
    name: "payment_sales_invoices.details",
    component: () =>
        import ("@/view/pages/modules/sales/paymentSalesInvoices/Details.vue"),
    meta: {"role":["payment_sales_invoices.details"]},
  },
  {
    path: "sales/payment_sales_invoices/payment-sales-details/:id",
    name: "payment_sales_invoices.payment_sales_details",
    component: () =>
        import ("@/view/pages/modules/sales/paymentSalesInvoices/paymentSalesDetails.vue"),
    meta: {"role":["payment_sales_invoices.details"]},
  },
  {
    path: "employees/attachment-notes/create/:type_id/:type",
    name: "attachment-notes.create",
    component: () =>
        import ("@/view/pages/modules/sales/attachmentNotes/Form.vue"),
    meta: {"role":["attachment_notes.create"]},
  },
  {
    path: "sales/general_sales",
    name: "general-sales.index",
    component: () =>
        import ("@/view/pages/modules/sales/generalSales/Index.vue"),
    meta: {"role":["general_sales.list"]},
  },
  {
    path: "sales/general_sales/payment_details/:id",
    name: "general_sales.payment_details",
    component: () =>
        import ("@/view/pages/modules/sales/invoices/PaymentDetails.vue"),
    meta: {"role":["sales_invoices.payment_details"]},
  },
  {
    path: "sales/general_sales/create",
    name: "general-sales.create",
    component: () =>
        import ("@/view/pages/modules/sales/generalSales/Form.vue"),
    meta: {"role":["general_sales.create"]},
  },
  {
    path: "sales/general_sales/edit/:id",
    name: "general-sales.edit",
    component: () =>
        import ("@/view/pages/modules/sales/generalSales/Form.vue"),
    meta: {"role":["general_sales.update"]},
  },
  {
    path: "sales/general_sales/view/:id",
    name: "general-sales.view",
    component: () =>
        import ("@/view/pages/modules/sales/generalSales/View.vue"),
    meta: {"role":["general_sales.view"]},
  },
  {
    path: "sales/general_sales/clone/:clone_id",
    name: "general-sales.clone",
    component: () =>
        import ("@/view/pages/modules/sales/generalSales/Form.vue"),
    meta: {"role":["general_sales.clone"]},
  },
  {
    path: "sales/general_sales_rent",
    name: "general_sales_rent.index",
    component: () =>
        import ("@/view/pages/modules/sales/generalSalesRent/Index.vue"),
    meta: {"role":["general_sales_rent.list"]},
  },
  {
    path: "sales/general_sales_rent/create",
    name: "general_sales_rent.create",
    component: () =>
        import ("@/view/pages/modules/sales/generalSalesRent/Form.vue"),
    meta: {"role":["general_sales_rent.create"]},
  },
  {
    path: "sales/general_sales_rent/edit/:id",
    name: "general_sales_rent.edit",
    component: () =>
        import ("@/view/pages/modules/sales/generalSalesRent/Form.vue"),
    meta: {"role":["general_sales_rent.update"]},
  },
  {
    path: "sales/general_sales_rent/clone/:clone_id",
    name: "general_sales_rent.clone",
    component: () =>
        import ("@/view/pages/modules/sales/generalSalesRent/Form.vue"),
    meta: {"role":["general_sales_rent.clone"]},
  },
  {
    path: "sales/sales_rent",
    name: "sales_rent.index",
    component: () =>
        import ("@/view/pages/modules/sales/salesRent/Index.vue"),
    meta: {"role":["sales_rent.list"]},
  },
  {
    path: "sales/sales_rent/create",
    name: "sales_rent.create",
    component: () =>
        import ("@/view/pages/modules/sales/salesRent/Form.vue"),
    meta: {"role":["sales_rent.create"]},
  },
  {
    path: "sales/sales_rent/edit/:id",
    name: "sales_rent.edit",
    component: () =>
        import ("@/view/pages/modules/sales/salesRent/Form.vue"),
    meta: {"role":["sales_rent.update"]},
  },
  {
    path: "sales/sales_rent/clone/:clone_id",
    name: "sales_rent.clone",
    component: () =>
        import ("@/view/pages/modules/sales/salesRent/Form.vue"),
    meta: {"role":["sales_rent.clone"]},
  },
  {
    path: "sales/cancel-partial-reservation/:id",
    name: "sales_rent.clone",
    component: () =>
        import ("@/view/pages/modules/sales/salesRent/CancelPartialReservation.vue"),
  },
  {
    path: "sales/general_sales_refund",
    name: "general_sales_refund.index",
    component: () =>
        import ("@/view/pages/modules/sales/generalRefund/Index.vue"),
    meta: {"role":["general_sales_refund.list"]},
  },
  {
    path: "sales/general_sales_refund/create/:general_sales_invoice_id",
    name: "general_sales_refund.create",
    component: () =>
        import ("@/view/pages/modules/sales/generalRefund/Form.vue"),
    meta: {"role":["general_sales_refund.create","general_sales.sales_refund"]},
  },
  {
    path: "sales/general_sales_refund/edit/:id",
    name: "general_sales_refund.edit",
    component: () =>
        import ("@/view/pages/modules/sales/generalRefund/Form.vue"),
    meta: {"role":["general_sales_refund.update"]},
  },
  {
    path: "sales/sales-quotations",
    name: "sales-quotations.index",
    component: () =>
        import ("@/view/pages/modules/sales/salesQuotations/Index.vue"),
    meta: {"role":["sales_quotations.list"]},
  },
  {
    path: "sales/sales-quotations/create/:quotation_requests_id?",
    name: "sales-quotations.create",
    component: () =>
        import ("@/view/pages/modules/sales/salesQuotations/Form.vue"),
    meta: {"role":["sales_quotations.create","sales_quotation_request.convert_to_sales_quotations"]},
  },
  {
    path: "sales/sales-quotations/edit/:id",
    name: "sales-quotations.edit",
    component: () =>
        import ("@/view/pages/modules/sales/salesQuotations/Form.vue"),
    meta: {"role":["sales_quotations.update"]},
  },
  {
    path: "sales/sales-quotations/view/:id",
    name: "sales-quotations.view",
    component: () =>
        import ("@/view/pages/modules/sales/salesQuotations/View.vue"),
    meta: {"role":["sales_quotations.list"]},
  },
  {
    path: "sales/general-sales-quotations",
    name: "general-sales-quotations.index",
    component: () =>
        import ("@/view/pages/modules/sales/generalSalesQuotations/Index.vue"),
    meta: {"role":["general_sales_quotations.list"]},
  },
  {
    path: "sales/general_sales/create/:quotation_requests_id?/:quotation_type?",
    name: "general-sales.create",
    component: () =>
        import ("@/view/pages/modules/sales/generalSales/Form.vue"),
    meta: {"role":["general_sales_quotations.create","general_sales_quotations.convert_to_sales_quotations"]},
  },
  {
    path: "sales/general-sales-quotations/create/:quotation_requests_id?",
    name: "general-sales-quotations.create",
    component: () =>
        import ("@/view/pages/modules/sales/generalSalesQuotations/Form.vue"),
    meta: {"role":["general_sales_quotations.create","general_sales_quotations.convert_to_sales_quotations"]},
  },
  {
    path: "sales/general-sales-quotations/edit/:id",
    name: "general-sales-quotations.edit",
    component: () =>
        import ("@/view/pages/modules/sales/generalSalesQuotations/Form.vue"),
    meta: {"role":["general_sales_quotations.update"]},
  },
  {
    path: "sales/general-sales-quotations/view/:id",
    name: "general-sales-quotations.view",
    component: () =>
        import ("@/view/pages/modules/sales/generalSalesQuotations/View.vue"),
    meta: {"role":["general_sales_quotations.list"]},
  },
  {
    path: "sales/sales-quotation-requests",
    name: "sales-quotation-requests.index",
    component: () =>
        import ("@/view/pages/modules/sales/salesQuotationRequest/Index.vue"),
    meta: {"role":["sales_quotation_request.list"]},
  },
  {
    path: "sales/sales-quotation-requests/create",
    name: "sales-quotation-requests.create",
    component: () =>
        import ("@/view/pages/modules/sales/salesQuotationRequest/Form.vue"),
    meta: {"role":["sales_quotation_request.create"]},
  },
  {
    path: "sales/sales-quotation-requests/edit/:id",
    name: "sales-quotation-requests.edit",
    component: () =>
        import ("@/view/pages/modules/sales/salesQuotationRequest/Form.vue"),
    meta: {"role":["sales_quotation_request.update"]},
  },
  {
    path: "sales/sales-quotation-requests/view/:id",
    name: "sales-quotation-requests.view",
    component: () =>
        import ("@/view/pages/modules/sales/salesQuotationRequest/View.vue"),
    meta: {"role":["sales_quotation_request.list"]},
  },
  {
    path: "sales/sales-tax-settings",
    name: "sales-tax-settings.list",
    component: () =>
        import ("@/view/pages/modules/sales/salesTaxSettings/Form.vue"),
    meta: {"role":["tax_setting_sales.update"]},
  },
  {
    path: "sales/invoice-status/:id/:type?",
    name: "invoice-status.index",
    component: () =>
        import ("@/view/pages/modules/sales/invoiceStatus/Index.vue"),
    meta: {"role":["sales_invoices.invoice_status","general_sales.invoice_status"]},
  },
  {
    path: "sales/debits",
    name: "debits.index",
    component: () =>
        import ("@/view/pages/modules/sales/debits/Index.vue"),
    meta: {"role":["sales_debit.list"]},
  },
  {
    path: "sales/debits/create/:sale_invoice_id?",
    name: "debits.create",
    component: () =>
        import ("@/view/pages/modules/sales/debits/Form.vue"),
    meta: {"role":["sales_debit.create","sales_invoices.add_sales_debit"]},
  },
  {
    path: "sales/debits/edit/:id",
    name: "debits.edit",
    component: () =>
        import ("@/view/pages/modules/sales/debits/Form.vue"),
    meta: {"role":["sales_debit.update"]},
  },
  {
    path: "sales/general-debits",
    name: "general-debits.index",
    component: () =>
        import ("@/view/pages/modules/sales/generalDebits/Index.vue"),
    meta: {"role":["general_debit.list"]},
  },
  {
    path: "sales/general-debits/create/:sale_invoice_id?",
    name: "general-debits.create",
    component: () =>
        import ("@/view/pages/modules/sales/generalDebits/Form.vue"),
    meta: {"role":["general_debit.create","general_sales.add_sales_debit"]},
  },
  {
    path: "sales/general-debits/edit/:id",
    name: "general-debits.edit",
    component: () =>
        import ("@/view/pages/modules/sales/generalDebits/Form.vue"),
    meta: {"role":["general_debit.update"]},
  },
  {
    path: "sales/sales-laundries",
    name: "sales_laundries.index",
    component: () =>
        import ("@/view/pages/modules/sales/laundrySales/Index.vue"),
    meta: {"role":["sales_laundry_services.list"]},
  },
  {
    path: "sales/sales-laundries/create/:quotation_requests_id?/:quotation_type?",
    name: "sales_laundries.create",
    component: () =>
        import ("@/view/pages/modules/sales/laundrySales/Form.vue"),
    meta: {"role":["sales_laundry_services.create","sales_quotations.convert_to_sales_invoice"]},
  },
  {
    path: "sales/sales-laundries/edit/:id",
    name: "sales_laundries.edit",
    component: () =>
        import ("@/view/pages/modules/sales/laundrySales/Form.vue"),
    meta: {"role":["sales_laundry_services.update"]},
  },
  {
    path: "sales/sales-laundries/clone/:clone_id",
    name: "sales_laundries.clone",
    component: () =>
        import ("@/view/pages/modules/sales/laundrySales/Form.vue"),
    meta: {"role":["sales_laundry_services.clone"]},
  },
  {
    path: "sales/credits/payment-details/:id",
    name: "credits.payment_details",
    component: () =>
        import ("@/view/pages/modules/sales/credits/PaymentDetails.vue"),
    meta: {"role":["credits.payment_details"]},
  },
  {
    path: "sales/general-credits/payment-details/:id",
    name: "general-credits.payment_details",
    component: () =>
        import ("@/view/pages/modules/sales/generalCredits/PaymentDetails.vue"),
    meta: {"role":["general_credits.payment_details"]},
  },
  {
    path: "sales/sales-refund/payment-details/:id",
    name: "sales_refund.payment_details",
    component: () =>
        import ("@/view/pages/modules/sales/refund/PaymentDetails.vue"),
    meta: {"role":["sales_refund.payment_details"]},
  },
  {
    path: "sales/general_sales_refund/payment-details/:id",
    name: "general_sales_refund.payment_details",
    component: () =>
        import ("@/view/pages/modules/sales/generalRefund/PaymentDetails.vue"),
    meta: {"role":["general_sales_refund.payment_details"]},
  },
  {
    path: "sales/sync_sales_invoices",
    name: "sync_sales_invoices.list",
    component: () =>
        import ("@/view/pages/modules/sales/syncInvoices/Index.vue"),
    meta: {"role":["statistics_documents.list"]},
  },

];