import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
// import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import "@/core/services/can.service";
import Multiselect from 'vue-multiselect'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import { ServerTable } from 'vue-tables-2';
import { VueEditor } from "vue2-editor";
import DownloadExcel from "vue-json-excel";
import { Bar } from 'vue-chartjs/legacy';
import VueBarcode from '@chenfengyuan/vue-barcode';
/**
 * FireBase
 */
require("@/core/services/firebase");
// import FireBaseService from "@/core/services/firebase.service";

// import $ from "jquery";
// import jQuery from "jquery";

// import * as $ from 'jquery';
// window['jQuery'] = window['$'] = $;

// import table2csv from "table2csv";

// router.beforeEach((to, from, next) => {
//     console.log(1)
//     // Ensure we checked auth before each page load.
//     Promise.all([store.dispatch(VERIFY_AUTH)]).then(()=>{
//         console.log(2)
//         // reset config to initial state
//         store.dispatch(RESET_LAYOUT_CONFIG);
//
//         const role = to.meta;
//         const currentUserRoles = Permissions.getPermission();
//         let _is_has_role = false;
//
//         if (role && role.role) {
//             role.role.forEach((_role)=>{
//                 if (currentUserRoles.includes(_role)) {
//                     _is_has_role = true;
//                 }
//             })
//             if (_is_has_role){
//                 return next();
//             }else {
//                 return next({ path: "/403" });
//             }
//         }
//         next();
//     });
//
//
//
//     // Scroll page to top on every route change
//     setTimeout(() => {
//         window.scrollTo(0, 0);
//     }, 100);
// });
Vue.config.productionTip = false;
Vue.use(ServerTable, {
    texts: {
        count: i18n.t('paginationCount'),
        first: i18n.t('paginationFirst'),
        last: i18n.t('paginationLast'),
        filter: i18n.t('paginationFilter'),
        filterPlaceholder: i18n.t('Search_query'),
        limit: i18n.t('paginationLimit'),
        page: i18n.t('paginationPage'),
        noResults: i18n.t('paginationNoResult'),
        filterBy: i18n.t('paginationFilterBy'),
        loading: i18n.t('paginationLoading'),
        defaultOption: i18n.t('paginationDefaultOption'),
        columns: i18n.t('columns'),
    }
});
Vue.use(VueEditor);

Vue.use(Bar);


// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
import _ from "lodash";
Object.defineProperty(Vue.prototype, '$_', { value: _ });

import moment from "moment";
Object.defineProperty(Vue.prototype, '$moment', { value: moment });

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "@/core/plugins/sweetAlert2";
import VJstree from 'vue-jstree';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

Vue.use(VJstree);
Vue.component('multiselect', Multiselect);
// Vue.component('table2csv', table2csv);



Vue.use(VueTelInput, {
    autoDefaultCountry: false,
    validCharactersOnly: true,
    mode: 'international',
    defaultCountry: 'SA',
    inputOptions: {
        showDialCode: true,
        styleClasses: 'form-control',
    },
    dropdownOptions: {
        showDialCodeInList: true,
        showDialCodeInSelection: true,
        showFlags: true,
        showSearchBox: true,
    }
});


/*barcode reader*/
import VueBarcodeScanner from './core/plugins/barcode-scanner';
Vue.use(VueBarcodeScanner)

Vue.component('upload-file', require("@/view/content/UploadFileComponent").default);
Vue.component('upload-avatar', require("@/view/content/UploadAvatarComponent").default);
Vue.component('upload-image', require("@/view/content/UploadImageComponent").default);
Vue.component('upload-file-excel', require("@/view/content/UploadFileExcelComponent").default);
Vue.component("download-excel", DownloadExcel);
Vue.component('export-data', require("@/view/content/ExportData").default);
Vue.component('import-excel-data', require("@/view/content/ImportDataExcelComponent").default);
Vue.component('custom-export-data', require("@/view/content/CustomExportData").default);
Vue.component('custom-upload-image-2', require("@/view/content/CustomUploadImage2").default);
Vue.component('banner-upload-image', require("@/view/content/BannerUploadImage.vue").default);
Vue.component(VueBarcode.name, VueBarcode);

// API service init
ApiService.init();
// FireBaseService.init();

// Remove this to disable mock API
// MockService.init();


new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount("#app");