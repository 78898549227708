<template>
  <div>
    <div class="col-md-3">
      <div class="image-input image-input-outline" id="kt_profile_avatar">
        <template v-if="loader_progress">
          <div class="image-input-wrapper box-shadow-2">
            <span class="process-ts"><i class="fa fa-spinner spinner"></i></span>
          </div>
        </template>
        <div v-else class="image-input-wrapper box-shadow-2"
             :style="{height: `${heightContainer}px`, width: `${widthContainer}px`, 'background-image': `url(${imgDataUrl ? imgDataUrl : previewImage })`, 'background-size': '100%'}">

        </div>
        <label
            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
            data-action="change"
            data-toggle="tooltip"
            :title="$t('edit')"
            data-original-title="Change avatar"
            @click="toggleShow"
        >
          <i class="fa fa-pen icon-sm text-muted p-0"></i>
          <input type="hidden" name="profile_avatar_remove"/>
        </label>
        <span
            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
            data-action="remove"
            data-toggle="tooltip"
            :title="$t('remove')"
            v-if="imgDataUrl && previewImage != imgDataUrl"
            @click="deleteFile">
                <i class="ki ki-bold-close icon-xs text-muted p-0"></i>
              </span>
      </div>
      <!--      <span class="form-text text-muted">{{$t('allowed_file_types')}} : png, jpg, jpeg.</span>-->
      <span v-if="validation && validation.file" class="form-text fv-plugins-message-container invalid-feedback">
        {{ validation.file[0] }}
    </span>
    </div>

    <avatar-cropper
        v-model="show"
        :output-mime="cropperOutputMime"
        :request-options="headers"
        :upload-handler="cropperHandler"
        :labels="labels"
        :cropper-options="cropperOptions"
        :output-options="{width: widthCropper, height: heightCropper, maxWidth: widthCropper, maxHeight: heightCropper}"
    />
  </div>
</template>

<script>
import 'babel-polyfill';
import avatarCropper from 'vue-avatar-cropper';
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "BannerUploadImage",
  props: {
    upload: null,
    imageUrl: null,
    startLink: null,
    heightContainer: {default: 134},
    widthContainer: {default: 606},
    heightCropper: {default: 174},
    widthCropper: {default: 771},
  },
  data() {
    return {
      lang: 'ar',
      show: false,
      previewImage: "https://sajlha.net/default-image.png",
      url: null,
      params: {
        upload: this.upload,
      },
      headers: {},
      imgDataUrl: '', // the datebase64 url of created image

      cropperOutputMime: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon, image/svg+xml',
      labels: {submit: this.$t('save'), cancel: this.$t('cancel')},
      cropperOptions: {
        aspectRatio: 0,
        autoCropArea: 1,
        viewMode: 1,
        movable: false,
        zoomable: true,
      },
      validation: null,
      loader_progress: false,
    }
  },
  components: {
    'avatar-cropper': avatarCropper
  },
  watch: {
    imageUrl: function (val) {
      if (val) {
        this.imgDataUrl = val;
      }
    }
  },
  methods: {

    cropperHandler(cropper) {
      let imgdat = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime);
      let type = imgdat.split(';')[0].split('/')[1];

      console.log(type.toLowerCase());

      console.log(!['jpg'].includes(type.toLowerCase()));

      if (!['jpg'].includes(type.toLowerCase())) {
        console.log('Invalid file type. Only JPG and SVG are allowed.');
        // throw new Error('Invalid file type. Only JPG and SVG are allowed.');
      }

      let file = this.dataURLtoFile(imgdat, "test." + type);
      let formData = new FormData();
      formData.append('file', file);
      formData.append('upload', this.params.upload);
      this.loaderProgress(true);

      ApiService.post(this.url, formData).then(response => {
        this.cropUploadSuccess(response.data)
        Vue.prototype.$postStatus = true;
        this.loaderProgress(false);
      }).catch((error) => {
        // this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
        Vue.prototype.$postStatus = true;
        this.loaderProgress(false);
      });
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type: mime});
    },
    toggleShow() {
      this.show = !this.show;
    },


    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData) {
      let file = {
        path: jsonData.path,
        name: jsonData.name,
        pathDB: jsonData.location,
        upload_date_time: jsonData.upload_date_time,
      };
      this.$emit('file', file);
      this.file = file;
    },

    deleteFile() {
      this.imgDataUrl = '';
      let file = {
        path: null,
        name: null,
        pathDB: null,
      };
      this.file = file;

      this.$emit('file', file);
    },
    loaderProgress(status) {
      this.loader_progress = status;
    },
  },
  mounted() {
    this.url = ApiService.getBaseURL() + (this.startLink ? '/' + this.startLink : '') + '/uploads/upload-file';
  }
}
</script>

<style scoped>

.avatar {
  width: 110px;
  position: relative;
}

.avatar-holder {
  width: 150px;
  height: 150px;
  display: inline-block;
  overflow: hidden;
  border: solid 2px #ffb803;
  padding: 15px;
}

.avatar-holder img {
  width: 100%;
  height: 100%;
}

.avatar.avatar-circle .avatar-upload i, .avatar.avatar-circle .avatar-upload-trash i {
  color: #ffffff !important;
  font-size: 12px;
}

.avatar.avatar-circle .avatar-upload {
  right: 8px !important;
  top: 10px !important;
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #06503f;
  padding: 5px 8px;
  border-radius: 50%;
  cursor: pointer;
}

.avatar.avatar-circle .avatar-upload-trash {
  right: 8px !important;
  bottom: 10px !important;
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #d71426;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
}

.margin-top-20 {
  margin-top: -20px;
}

.process-ts i.fa {
  font-size: 84px;
  text-align: center;
  display: block;
  width: 76px;
  height: 90px;
}

.process-ts .spinner:before {
  width: 3.5rem;
  height: 3.5rem;
  margin-top: -0.75rem;
}

.box-shadow-2 {
  box-shadow: 0 0rem 0.5rem 0.1rem rgba(0, 0, 0, 0.04) !important;
  -webkit-box-shadow: 0 0rem 0.5rem 0.1rem rgba(0, 0, 0, 0.04) !important;
}
</style>